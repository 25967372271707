<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Flujo de Ventas - Potenciales</h4>
            <div class="small text-muted">Seguimiento de venta sobre un nuevo potencial cliente.</div>
          </b-col>

          <b-col sm="5">
            <b-dropdown right text="Agregar" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Potencial">
              <b-dropdown-item @click="addPerson()"><i class="fa fa-plus"></i> Persona</b-dropdown-item>
              <b-dropdown-item @click="addCompany()"><i class="fa fa-plus"></i> Empresa</b-dropdown-item>
            </b-dropdown>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="10">
            <b-row>
              <b-col sm="3">
                <b-row>
                  <b-col sm="6">
                    <v-select :options="arr.select.type" v-model="filter.type" placeholder="Tipo" :multiple="true" :select-on-tab="true" class="crud-leads-select"></v-select>
                  </b-col> 
                  <b-col sm="6">
                    <v-select :options="arr.select.status" v-model="filter.status" placeholder="Etapa" :multiple="true" :select-on-tab="true" class="crud-leads-select"></v-select>
                  </b-col>                            
                </b-row>
              </b-col>

              <b-col sm="4">
                <b-row>
                  <b-col sm="6">
                    <v-select :options="arr.select.categories" v-model="filter.categories" placeholder="Categoría" :multiple="true" :select-on-tab="true" class="crud-leads-select"></v-select>
                  </b-col>   
                  <b-col sm="6">
                    <v-select :options="arr.select.sources" v-model="filter.sources" placeholder="Origen" :multiple="true" :select-on-tab="true" class="crud-leads-select"></v-select>
                  </b-col>      
                </b-row>
              </b-col>

              <b-col sm="1">
                <b-button variant="outline-dark" @click="filterQuery()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                                   
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-link>
              <export-excel
                class = 'pull-right mt-3'
                :data = "arr.export"
                worksheet = "Potenciales Clientes"
                name = "potenciales-clientes.xls">
                Exportar Datos
              </export-excel>            
            </b-link>
          </b-col>
        </b-row>

        <b-tabs class="mt-3">
          <b-tab title="Lista" :active="tabs.list" @click="loadSalesFlowList()">

            <b-row class="mt-3">
              <b-col>
                <b-table class="mb-0 table-sales-flow-custom"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="formartedItems"
                        :fields="table.fields"                    
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"                     
                        v-if="table.items.length">

                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template>       

                    <template v-slot:row-details="row">
                      <b-card>
                        <b-row>
                          <b-col md="12">
                            <b-row>
                              <b-col md="6">
                                <div class="crud-leads-title-attachment mb-2">
                                  <b>Adjuntos:</b> {{row.item.name}} {{row.item.last_name}}
                                </div>
                              </b-col>
                              <b-col md="6">
                                <b-button @click="addSub(row.item)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Adjunto">
                                  <i class="fa fa-plus"></i> Agregar
                                </b-button>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col md="12">
                                <b-table class="mb-0"
                                        responsive
                                        head-variant="dark"
                                        :hover="true"
                                        :small="true"
                                        :fixed="false"
                                        stacked="sm"
                                        :items="tableSub.items"
                                        :fields="tableSub.fields"                            
                                        v-if="tableSub.items.length">   
                                  
                                  <template v-slot:cell(id)="data">
                                    <b>{{ data.item.id }}</b>
                                  </template>
                                                        
                                  <template v-slot:cell(file)="data">                                                            
                                    <b-link :href="data.item.file" v-if="data.item.file" target="_blank">
                                      <b>Ver Archivo</b>
                                    </b-link>                  
                                    <b-badge variant="secondary" v-else>Archivo no disponible</b-badge>                  
                                  </template>                                                    
                                  
                                  <template v-slot:cell(f_action)="data">
                                    <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                      <b-dropdown-item @click="editSub(data.item)">
                                        <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                      </b-dropdown-item>
                                      <b-dropdown-item @click="removeSub(data.item)">
                                        <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                      </b-dropdown-item>
                                    </b-dropdown>
                                  </template>

                                </b-table>
                                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>
                  
                    <template v-slot:cell(id)="data">
                      <b>{{ data.item.id }}</b>
                    </template>

                    <template v-slot:cell(date)="data">
                      {{ moment(data.item.date).format('DD MMMM') }}
                    </template>

                    <template v-slot:cell(name)="data">
                      <div v-if="data.item.iscompany">
                        <span class="crud-leads-table-title-company">Empresa</span> <br>
                        <span class="crud-leads-table-title-name">{{ data.item.name }}</span> <br>
                        <div v-if="data.item.fiscal_condition">
                          <span class="crud-leads-table-title">Cond. Fiscal:</span> {{ data.item.fiscal_condition.name }} <br>
                        </div>
                        <div v-if="data.item.document_fiscal_type">
                          <span class="crud-leads-table-title">{{ data.item.document_fiscal_type.name }}:</span> {{ data.item.documents_fiscal_number }} 
                        </div>
                      </div>
                      <div v-else>
                        <span class="crud-leads-table-title-person">Persona</span> <br>
                        <span class="crud-leads-table-title-name">{{ data.item.name }} {{ data.item.last_name }}</span> <br>
                        <div v-if="data.item.document_type">
                          <span class="crud-leads-table-title">{{ data.item.document_type.name }}:</span> {{ data.item.documents_number }} <br>
                        </div>                    
                        <div v-if="data.item.document_fiscal_type">
                          <span class="crud-leads-table-title">{{ data.item.document_fiscal_type.name }}:</span> {{ data.item.documents_fiscal_number }} 
                        </div>
                      </div>
                    </template>

                    <template v-slot:cell(contact)="data">
                      <div v-if="data.item.email">
                        <span class="crud-leads-table-title">Email:</span> 
                        <a :href="'mailto:' + data.item.email"> {{ data.item.email }}</a> <br>
                      </div>
                      <div v-if="data.item.mobile">
                        <span class="crud-leads-table-title">Celular:</span> 
                        <a :href="'tel:' + data.item.mobile"> {{ data.item.mobile }}</a> <br>
                      </div>                          
                      <div v-if="data.item.work_phone">
                        <span class="crud-leads-table-title">Laboral:</span> 
                        <a :href="'tel:' + data.item.work_phone"> {{ data.item.work_phone }}</a> <br>
                      </div>        
                      <div v-if="data.item.phone">
                        <span class="crud-leads-table-title">Particular:</span>
                        <a :href="'tel:' + data.item.phone"> {{ data.item.phone }}</a>
                      </div>                                    
                    </template>

                    <template v-slot:cell(info)="data">
                      <div v-if="data.item.source">
                        <span class="crud-leads-table-title">Origen:</span> {{ data.item.source.name }}
                      </div>
                      <div v-if="data.item.categories">
                        <span class="crud-leads-table-title">Categoría:</span> {{ data.item.categories.name }}
                      </div>                   
                    </template>
                            
                    <template v-slot:cell(status)="data">
                      <div :style="'color:' + getStatusTable(data.item.status).color" class="crud-leads-table-title-status">
                        {{ getStatusTable(data.item.status).label }}
                      </div>

                      <div v-if="getStatusTable(data.item.status).code == 'lost'">
                        <div v-if="data.item.lost_motive">
                          <span class="crud-leads-table-title">Motivo:</span> {{data.item.lost_motive.name}}
                        </div>
                      </div>
                      <div v-if="getStatusTable(data.item.status).code == 'won'">

                        <div v-if="data.item.external_approval">
                          Confirmación Externa                                             
                        </div>                  
                        <div v-if="data.item.firm">
                          <b-link :href="data.item.firm[0]" target="_blank">
                            Ver Firma
                            <b-icon icon="chevron-double-right"></b-icon>
                          </b-link>
                        </div>                                                            
                      </div>                  
                    </template>                                    

                    <template v-slot:cell(work_price)="data">
                      <div v-if="data.item.work_recurrent" title="Pago Recurrente">
                        <b>{{ Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(data.item.work_price) }}</b> (R)
                      </div>
                      <div v-else title="Pago Único">
                        <b>{{ Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(data.item.work_price) }}</b>
                      </div>
                    </template>                            
                    
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right"
                                  @show="setConfigTableSalesFlowShow"
                                  @hide="setConfigTableSalesFlowHide"                              >                                       
                        <!--
                        <b-dropdown-item @click="irDetail(data.item)">
                          <b-icon icon="card-text"></b-icon> Detalle
                        </b-dropdown-item>                          
                        -->
                        <b-dropdown-item @click="externalApproval(data.item)" v-if="data.item.status=='approval' && parameters.haveExternalApproval">
                          <b-icon icon="box-arrow-up-right"></b-icon> Aprobación Externa
                        </b-dropdown-item>       

                        <b-dropdown-item @click="openContract(data.item)" v-if="data.item.status=='won' && parameters.haveContract">
                          <i class="fa fa-print crud-lead-print-icon"></i> Contrato
                        </b-dropdown-item>   
                        <b-dropdown-item @click="openChangeFirm(data.item)" v-if="data.item.status=='won' && parameters.haveContract && parameters.haveExternalApproval">
                          <b-icon icon="pen"></b-icon> Cambiar Firma
                        </b-dropdown-item>                                             
                        
                        <b-dropdown-header>Estados</b-dropdown-header>
                        <b-dropdown-item @click="nextStage(data.item)" v-if="showNextStage(data.item)">
                          <b-icon icon="arrow-return-right"></b-icon> Próxima Etapa
                        </b-dropdown-item>    
                        <b-dropdown-item @click="previousStage(data.item)" v-if="showPreviousStage(data.item)">
                          <b-icon icon="arrow-return-left"></b-icon> Etapa Anterior
                        </b-dropdown-item>                                                                                         
                        <b-dropdown-header>Acciones</b-dropdown-header>
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
              
            <b-row class="mt-2">              
              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(formartedItems)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>
              </b-col>          
            </b-row>

          </b-tab>

          <b-tab title="Kanban" :active="tabs.kanban" @click="loadSalesFlowKanban(true)">
            <kanban-board :stages="kanban.stages" :blocks="kanban.blocks" @update-block="updateBlock" :key="kanban.forceUpdate">
              <div v-for="stage in kanban.stages" :slot="stage" :key="stage" class="w-100">
                <h2>{{ getStatusName(stage) }}</h2>
                <div v-html="getTotalByStatus(stage)" class="w-100 text-right"></div>                
              </div>
              
              <div v-for="block in kanban.blocks" :slot="block.id" :key="block.id" @click="edit(block.data)">
                <div>                  
                  <strong v-if="block.data.iscompany==1">Empresa</strong>
                  <strong v-else>Personal</strong>

                  <span class="sales-flow-kanban-date">{{ moment(block.data.created_at).fromNow() }}</span>
                </div>
                <div>
                  <u>{{ block.data.name }}</u>
                </div>
                <div>{{ block.data.categories.name }} | {{ block.data.source.name }}</div>
                <hr class="sales-flow-kanban-hr">
                <div :title="block.data.work_recurrent?'Pago Recurrente':'Pago Único'">
                  Estimado: <b>{{ Intl.NumberFormat('es-AR',{style:'currency',currency:block.data.currency.code}).format(block.data.work_price) }}</b>
                  &nbsp;<span v-if="block.data.work_recurrent">(R)</span>
                </div>                
              </div>                            
            </kanban-board>
          </b-tab>

          <b-tab title="Análisis" :active="tabs.analysis" @click="loadSalesFlowAnalysis(true)">

            <div class="sales-flow-pivot">

              <pivot :data="pivot.data" 
                    :fields="pivot.fields" 
                    :available-field-keys="pivot.availableFieldKeys"
                    :row-field-keys="pivot.rowFieldKeys" 
                    :col-field-keys="pivot.colFieldKeys" 
                    :reducer="pivot.reducer"
                    :default-show-settings="false"
                    :is-data-loading="pivot.isDataLoading"
                    no-data-warning-text="No se encontraron datos para esta consulta"
                    available-fields-label-text="Campos Disponibles"
                    rows-label-text="Filas"
                    cols-label-text="Columnas"
                    hide-settings-text="Cerrar Configuración"
                    show-settings-text="Configurar Informe"
                    select-all-text="Seleccionar Todo"
                    unselect-all-text="Quitar selección">

                <template slot="value" slot-scope="{ value }">
                  {{ value.toLocaleString() }}     
                </template>
                
                <template v-slot:isCompany="{ value }">
                  <span v-if="value == '0'">Persona</span>
                  <span v-if="value == '1'">Empresa</span>
                </template>

                <template v-slot:workRecurrent="{ value }">
                  <span v-if="value == '0'">Pago Único</span>
                  <span v-if="value == '1'">Pago Recurrente</span>
                </template>

                <template v-slot:status="{ value }">
                  <span v-if="value == 'interested'">Interesados</span>
                  <span v-if="value == 'in process'">En Proceso</span>
                  <span v-if="value == 'approval'">Aprobación</span>
                  <span v-if="value == 'won'">Ganado</span>
                  <span v-if="value == 'lost'">Perdido</span>
                </template>                

                <template slot="loading">
                  <div class="text-center">
                    Cargando...
                  </div>
                </template>      

                <template slot="foot-customer" slot-scope="{ value }">
                  {{value}}
                </template>
              </pivot>   
              
            </div>

          </b-tab>          
        </b-tabs>

      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>       
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-card no-body>          
          <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-info" active-tab-class="crud-leads-bg-form" justified>    
            <b-tab title="Persona" v-if="!isCompany">
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nombre">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.name">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Apellido">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.last_name">
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Tipos de Documentos">
                      <v-select :options="arr.select.typesDocuments" v-model="crud.form.documents_types_id" placeholder="Tipos Documentos" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col>   
                  <b-col md="6">
                    <b-form-group label="Número Documento">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.documents_number">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  

                  <b-col md="6">
                    <b-form-group label="Tipos de Documentos Fiscales">
                      <v-select :options="arr.select.typesDocumentsFiscal" v-model="crud.form.documents_fiscal_types_id" placeholder="Tipos Documentos Fiscales" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col>  
                  <b-col md="6">
                    <b-form-group label="Número Documento Fiscal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.documents_fiscal_number">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 

                  <b-col md="6">
                    <b-form-group label="Categoría">
                      <v-select :options="arr.select.categories" v-model="crud.form.categories_id" placeholder="Categoría" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col> 
                  <b-col md="6">
                    <b-form-group label="Origen">
                      <v-select :options="arr.select.sources" v-model="crud.form.sources_id" placeholder="Origen" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col>

                  <b-col md="12">
                    <hr>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label="Actividad Principal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.main_activity">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 
                  <b-col md="12">
                    <b-form-group label="Observaciones">
                      <b-form-textarea                    
                        v-model="crud.form.observations"
                        size="md"
                        placeholder="Ingresar las observaciones..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>

            <b-tab title="Complementario" v-if="!isCompany && parameters.dataComplementary">
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Genero">
                      <v-select :options="arr.select.gender" v-model="crud.form.gender" placeholder="Genero" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col>   
                  <b-col md="6">
                    <b-form-group label="Estado Civil">
                      <v-select :options="arr.select.maritalStatus" v-model="crud.form.marital_status_id" placeholder="Estado Civil" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col>                                                                     
                  <b-col md="4">
                    <b-form-group label="Nacionalidad">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.nationality">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  
                  <b-col md="4">
                    <b-form-group label="Lugar de Nacimiento">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.place_birth">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  
                  <b-col md="4">
                    <b-form-group label="Fecha de Nacimiento">
                      <b-form-datepicker size="md" v-model="crud.form.birthday" placeholder="Fecha de Nacimiento" local="es"></b-form-datepicker>                      
                    </b-form-group>            
                  </b-col>                    
                </b-row>
              </b-card-text>
            </b-tab>

            <b-tab title="Empresa" active v-if="isCompany">   
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Razón Social">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.name">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Condición Fiscal">
                      <v-select :options="arr.select.fiscalConditions" v-model="crud.form.fiscal_conditions_id" placeholder="Condición Fiscal" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col> 

                  <b-col md="6">
                    <b-form-group label="Tipos de Documentos Fiscales">
                      <v-select :options="arr.select.typesDocumentsFiscal" v-model="crud.form.documents_fiscal_types_id" placeholder="Tipos Documentos Fiscales" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col>  
                  <b-col md="6">
                    <b-form-group label="Número Documento Fiscal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.documents_fiscal_number">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 

                  <b-col md="6">
                    <b-form-group label="Categoría">
                      <v-select :options="arr.select.categories" v-model="crud.form.categories_id" placeholder="Categoría" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col> 
                  <b-col md="6">
                    <b-form-group label="Origen">
                      <v-select :options="arr.select.sources" v-model="crud.form.sources_id" placeholder="Origen" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col>

                  <b-col md="12">
                    <hr>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label="Actividad Principal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.main_activity">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 
                  <b-col md="12">
                    <b-form-group label="Observaciones">
                      <b-form-textarea                    
                        v-model="crud.form.observations"
                        size="md"
                        placeholder="Ingresar las observaciones..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>                                    
                </b-row>
              </b-card-text>
            </b-tab>

            <b-tab title="Complementario" v-if="isCompany && parameters.dataComplementary">   
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Inscripcíon Registro Público">
                      <b-form-datepicker size="md" v-model="crud.form.registration_public_registry" placeholder="Inscripcíon Registro Público" local="es"></b-form-datepicker>                      
                    </b-form-group>                    
                  </b-col>                   
                  <b-col md="6">
                    <b-form-group label="Fecha Cierre Ejercicio">
                      <b-form-datepicker size="md" v-model="crud.form.year_end_date" placeholder="Fecha Cierre Ejercicio" local="es"></b-form-datepicker>                      
                    </b-form-group>                    
                  </b-col>

                  <b-col md="12">
                    <hr>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Distrito de Inscripción">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.registration_district">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  
 
                  <b-col md="6">
                    <b-form-group label="Fecha de Registro">
                      <b-form-datepicker size="md" v-model="crud.form.registration_date" placeholder="Fecha de Registro" local="es"></b-form-datepicker>                      
                    </b-form-group>                    
                  </b-col>  

                  <b-col md="3">
                    <b-form-group label="Acta Número">
                      <b-form-input type="number"
                                    size="md"
                                    v-model="crud.form.registration_number">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 
                  <b-col md="3">
                    <b-form-group label="Libro">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.registration_book">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  
                  <b-col md="3">
                    <b-form-group label="Tomo">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.registration_volume">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>   
                  <b-col md="3">
                    <b-form-group label="Follo">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.registration_sheet">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>                  

                  <b-col md="12">
                    <hr>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label="Dirección Correspondencia">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.address_correspondence">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>                                                                         
                </b-row>
              </b-card-text>
            </b-tab>  

            <b-tab title="Ubicación">               
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Dirección">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.address">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>          
                  <b-col md="4">
                    <b-form-group label="Ciudad">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.city">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>      
                  <b-col md="2">
                    <b-form-group label="Código Postal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.postal_code">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  
                  <b-col md="6">
                    <b-form-group label="Provincia">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.state">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  
                  <b-col md="6">
                    <b-form-group label="País">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.country">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>                    
                </b-row>             
              </b-card-text>
            </b-tab>
            
            <b-tab title="Contacto">   
              <b-card-text>
                <b-row>     
                  <b-col md="12">
                    <b-form-group label="Email">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.email">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 

                  <b-col md="12">
                    <hr>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Teléfono Particular">
                      <b-form-input type="number"                                    
                                    size="md"
                                    v-model="crud.form.phone">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 
                  <b-col md="4">
                    <b-form-group label="Teléfno Laboral/Comercial">
                      <b-form-input type="number"
                                    size="md"
                                    v-model="crud.form.work_phone">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>       
                  <b-col md="4">
                    <b-form-group label="Teléfono Celular">
                      <b-form-input type="number"
                                    size="md"
                                    v-model="crud.form.mobile">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>  

            <b-tab title="Valorización" v-if="parameters.valorization">
              <b-row>
                <b-col md="4">
                  <b-form-group label="Precio Estimado">
                    <b-input-group prepend="$" size="md">            
                      <b-form-input type="number"
                                    size="md"
                                    v-model="crud.form.work_price">
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>                                
                </b-col> 
                <b-col md="5">
                  <FindObject type="currency" 
                              @select-object="loadCurrency($event)" 
                              :valueID="crud.form.work_currency_id"/>
                </b-col>    
                <b-col md="3">
                  <b-form-checkbox v-model="crud.form.work_recurrent" 
                                    switch 
                                    size="sm"
                                    class="mt-4">
                    Pago Recurrente
                  </b-form-checkbox>            
                </b-col>                          
                <b-col md="12">
                  <b-form-group label="Trabajo a Presupuestar">
                    <b-form-textarea                    
                      v-model="crud.form.work_budget"
                      size="md"
                      placeholder="Ingresar las descripción del trabajo a prsupuestar..."
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>     
              </b-row>
            </b-tab>      

          </b-tabs>
        </b-card>    

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>
      
      <!-- CRUD GANADO O PERDIDO -->
      <b-modal v-model="modal.stage.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.stage.title}}
        </div>
        
        <b-row>
          <b-col md="12">
            <b-button-group style="width: 100%;">
              <b-button variant="success" @click="modal.stage.value='won'">Ganado</b-button>
              <b-button variant="danger" @click="modal.stage.value='lost'">Perdido</b-button>            
            </b-button-group>
          </b-col> 

          <b-col md="12" v-if="modal.stage.value=='won'" class="mt-3">
            <div class="crud-leads-modal-customer-won">
              <i class="fa fa-thumbs-up"></i> Cliente Ganado!!
            </div>
          </b-col>

          <b-col md="12" v-if="modal.stage.value=='lost'" class="mt-3">
            <div class="crud-leads-modal-customer-lost">
              <i class="fa fa-thumbs-down"></i> Cliente Perdido                  
            </div>
          </b-col>            
          <b-col md="12" v-if="modal.stage.value=='lost'" class="mt-3">
            <b-form-group label="Motivo de Pérdida">
              <v-select :options="arr.select.lostMotives" v-model="crud.form.lost_motives_id" placeholder="Motivo de Pérdida" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
            </b-form-group>                 
          </b-col>
        </b-row>                    
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeStageFinish()">Cancelar</b-button>
          <b-button variant="dark" @click="saveStageFinish()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-row>          
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formSub.name"                            
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>       
            <b-col md="12">
              <b-form-group label="Archivo">                
                <b-form-file v-if="!crud.formSub.fileLink" 
                             v-model="crud.formSub.file"                             
                             size="sm"
                             :state="Boolean(crud.formSub.file)"
                             placeholder="Elija un archivo o sueltelo aquí..."
                             drop-placeholder="Suelta el archivo aquí...">
                </b-form-file>      
                <div v-else>
                  <b-link :href="crud.formSub.fileLink" target="_blank">
                    <b>Ver Archivo</b>
                  </b-link>           
                  <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.formSub.fileLink=''" v-b-tooltip.hover title="Cargar otro archivo">
                    <i class="fa fa-refresh"></i>
                  </b-button>                                         
                </div>
              </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>     

      <!-- CONTRACT -->
      <b-modal v-model="modal.contract.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.contract.title}}
        </div>
                
        <b-row>
          <b-col lg="12" v-if="modal.contract.leadObject">
            <h5>
              {{this.modal.contract.leadObject.name}}
              <b-badge v-if="modal.contract.leadObject.iscompany" variant="danger">Empresa</b-badge>
              <b-badge v-else variant="primary">Persona</b-badge>
            </h5>
          </b-col>

          <b-col lg="4">            
            <v-select :options="arr.select.contracts" 
                      v-model="modal.contract.contractObject" 
                      placeholder="Contratos" 
                      :multiple="false" 
                      :select-on-tab="true" 
                      class="crud-leads-select"
                      @change="showContract">
            </v-select>            
          </b-col>
          <b-col lg="8" v-if="modal.contract.urlPDF" class="crud-leads-preview-open-pdf">
            <strong>
              <b-link :href="modal.contract.urlPDF" target="_blank">
                Abrir PDF ({{this.modal.contract.contractObject.label}})
                <b-icon icon="chevron-double-right"></b-icon>
              </b-link>
            </strong>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col lg="12">      
            <div class="crud-leads-preview-contract">
              <div v-if="modal.contract.content" 
                  class="crud-leads-preview-contract-html" 
                  v-html="modal.contract.content">
              </div>
              <div v-else 
                  class="crud-leads-preview-contract-alert">
                <b-alert show variant="primary">Seleccion un formato de contrato</b-alert>
              </div>
            </div>
          </b-col>          
        </b-row>                    
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.contract.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="printContract()">Crear Contrato</b-button>          
        </div>
      </b-modal>

      <!-- FIRMA -->
      <b-modal v-model="modal.firm.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="sm">
        <div slot="modal-header">
          {{this.modal.firm.title}}
        </div>
                
        <b-row>
          <b-col lg="12">
            <b-form-group label="Firmar" 
                          description="Firme en una hoja en blanco y luego suba una foto de la misma (100px alto / 150px ancho)"
                          class="sales-flow-leads-label-custom">
              <Imagen :images="modal.firm.imagen" 
                      @load-image="modal.firm.imagen = $event" 
                      typeImage="Firma" 
                      class="sales-flow-leads-image"/>                        
            </b-form-group>            
          </b-col>         
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.firm.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="changeFirm()">Cambiar Firma</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import FindObject from '@/components/inc/find/findObject' 
  import Vue from 'vue'
  import excel from 'vue-excel-export'
  import Param from '@/config/parameters'
  import Imagen from '@/components/inc/image/image' 
  import Storage from '@/handler/storageLocal'
  import Pivot from '@marketconnect/vue-pivot-table'
  import vueKanban from 'vue-kanban'
  
  Vue.use(excel)
  Vue.use(vueKanban)

  export default {
    components: {
      FindObject,
      Imagen,
      Pivot,
    },      
    data: () => {
      return {      
        access: {
          module_id: Modules.FLUJO_VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudLeadsSellers',
          elements: {}
        }, 
        parameters: {
          dataComplementary: Helper.hasParametersAccess(Param.P4),
          valorization: Helper.hasParametersAccess(Param.P5),
          haveExternalApproval: Helper.hasParametersAccess(Param.P6),
          haveContract: Helper.hasParametersAccess(Param.P16),          
        },            
        table : {
          items: [],
          fields: [],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 50,
        },
        tableSub : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},            
            {key: 'name', label: 'Nombre', class:"align-middle"},
            {key: 'file', label: 'Archivo', class:"align-middle"},    
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },        
        crud: {
          form: {
            id: 0,
            date: '',                                   
            name: '',
            last_name: '',
            address: '',
            city: '',
            postal_code: '',
            state: '',
            country: '',
            fiscal_conditions_id: 0,
            documents_types_id: 0,
            documents_number: '',
            documents_fiscal_types_id: 0,
            documents_fiscal_number: '',
            marital_status_id: 0,
            nationality: '',
            place_birth: '',
            birthday: null,
            gender: '',
            main_activity: '',
            phone: '',
            work_phone: '',
            mobile: '',
            email: '',
            status: '',
            address_correspondence: '',           
            registration_public_registry: null,
            year_end_date: null,
            registration_district: '',
            registration_number: '',
            registration_date: null,
            registration_book: '',
            registration_volume: '',
            registration_sheet: '',
            observations: '',        
            lost_motives_id: 0,
            categories_id: 0,
            sources_id: 0,
            staff_id: 0,
            staff: null,
            sellers_id: 0,  
            sellers: null,  
            iscompany: false,
            work_price: 0,
            work_budget: '',
            work_currency_id: 1,
            currency: null,
            work_recurrent: false,
            customers: null,
            customers_id: 0,
          },        
          formSub: {
            id: 0,
            leads_id: 0,
            leads: null,
            name: '',            
            file: null,     
            fileLink: '',            
          },                 
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          },                 
          stage: {
            active: false,
            title: '',
            value: '',
            id: 0
          },
          contract: {
            active: false,
            title: '',
            contractObject: null,
            leadID: 0,
            content: '',
            urlPDF: '',
            leadObject: null,
          },
          firm: {
            active: false,
            title: '',
            image: [],
            leadID: 0,            
          }
        },
        arr: {
          export: [],
          select : {                        
            staff: [],
            sellers: [],
            gender: [],
            status: [],
            lostMotives: [],
            categories: [],
            sources: [],
            fiscalConditions: [],
            typesDocuments: [],
            typesDocumentsFiscal: [],
            maritalStatus: [],    
            type: [],
            contracts: [],        
          },
          leads: [],
          contracts: [],
        },
        filter: {                    
          staff: [],
          sellers: [],          
          type: [],
          categories: [],
          sources: [],
          status: []
        },        
        pivot: {
          data: [],
          fields: [{
            key: 'customers',
            getter: item => (item.customer)?item.customer.name : 'Sin Asignar',
            label: 'Clientes',
            valueFilter: true,
          },{
            key: 'type',
            getter: item => item.iscompany,
            label: 'Tipo',
            valueFilter: true,
            headerSlotName: 'isCompany',            
            valueFilterSlotName: 'isCompany'            
          },{
            key: 'categories',
            getter: item => item.categories.name,
            label: 'Categoría',
            valueFilter: true,
          }, {
            key: 'source',
            getter: item => item.source.name,
            label: 'origen',
            valueFilter: true
          }, {
            key: 'status',
            getter: item => item.status,
            label: 'Estado',
            valueFilter: true,
            headerSlotName: 'status',            
            valueFilterSlotName: 'status'                    
          }, {
            key: 'work_currency',
            getter: item => item.currency.code,
            label: 'Moneda',
            valueFilter: true,
          }, {
            key: 'work_recurrent',
            getter: item => item.work_recurrent,
            label: 'Forma de Pago',
            valueFilter: true,
            headerSlotName: 'workRecurrent',            
            valueFilterSlotName: 'workRecurrent'            
          }, {
            key: 'date',
            getter: item => moment(item.date).format('DD/MM/YYYY'),
            label: 'Fecha',
            valueFilter: true
          }, {
            key: 'months',
            getter: item => moment(item.date).format('MMMM YYYY'),
            label: 'Mes',
            valueFilter: true
          }, {
            key: 'anio',
            getter: item => moment(item.date).format('YYYY'),
            label: 'Año',
            valueFilter: true
          }],   
          availableFieldKeys: ['date','anio','type','categories','source', 'customers'], 
          rowFieldKeys: ['status', 'work_recurrent'],
          colFieldKeys: ['months','work_currency'],
          reducer: (sum, item) => sum + parseFloat(item.work_price),         
          isDataLoading: false, 
        },  
        kanban: {
          stages: [],
          blocks: [],
          forceUpdate: 0.
        },
        tabs: {
          list: true,
          kanban: false,
          analysis: false,          
        }              
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {            
      this.setFieldTable()
      this.selectLoad()
      this.filterLoad()
      this.filterQuery()

      this.restoreStatusTabsStorage()
    },
    computed: {
      formartedItems () {
        if (!this.table.items) return []
        return this.table.items.map(item => {
          return item
        })
      },
      isCompany () {
        if(this.crud.form.iscompany) {
          return true
        } else {
          return false
        }
      },    
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },
    },      
    watch: {        
      'arr.leads': function (newQuestion, oldQuestion) {        
        this.kanban.forceUpdate = this.kanban.forceUpdate + 1
        this.loadSalesFlowKanban()
      }
    }, 
    methods: {
      // CARGAR CAMPOS TABLA
      setFieldTable(){
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class: "align-middle", width:"50px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class: "align-middle", width:"100px"})
        this.table.fields.push({key: 'name', label: 'Nombre', class: "align-middle", width:"250px"})
        this.table.fields.push({key: 'contact', label: 'Contacto', class: "align-middle", width:"250px"})
        this.table.fields.push({key: 'info', label: 'Info', class: "align-middle", width:"250px"})                
        if(this.parameters.valorization) {
          this.table.fields.push({key: 'work_price', label: 'Importe', class: "align-middle text-right", width:"150px"})
        }              
        this.table.fields.push({key: 'status', label: 'Etapa', class: "align-middle text-center", width:"150px"})
        this.table.fields.push({key: 'f_action', label:'', class: "align-middle", width:"100px"})
      },
      setConfigTableSalesFlowShow() {
        var arrTable = document.getElementsByClassName('table-sales-flow-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          if(this.parameters.haveNroInteral) {
            arrTable[i].style.minHeight = '390px'
          } else {
            arrTable[i].style.minHeight = '320px'
          }          
        }        
      },
      setConfigTableSalesFlowHide() {
        var arrTable = document.getElementsByClassName('table-sales-flow-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      }, 

      // CONFIG OPEN DETAIL TABLE
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {              
        this.arr.leads.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.filterQuery()
      },        
      
      // FORM MAIN
      addPerson() {        
        this.crud.form.iscompany = false
        this.add()
      },
      addCompany() {
        this.crud.form.iscompany = true
        this.add()
      },
      add() {        
        this.crud.form.id = 0
        this.crud.form.date = moment().format('YYYY-MM-DD')                                  
        this.crud.form.name = ''
        this.crud.form.last_name = ''
        this.crud.form.address = ''
        this.crud.form.city = ''
        this.crud.form.postal_code = ''
        this.crud.form.state = ''
        this.crud.form.country = ''
        this.crud.form.fiscal_conditions_id = 0
        this.crud.form.documents_types_id = 0
        this.crud.form.documents_number = ''
        this.crud.form.documents_fiscal_types_id = 0
        this.crud.form.documents_fiscal_number = ''
        this.crud.form.marital_status_id = 0
        this.crud.form.nationality = ''
        this.crud.form.place_birth = ''
        this.crud.form.birthday = null
        this.crud.form.gender = ''
        this.crud.form.main_activity = ''
        this.crud.form.phone = ''
        this.crud.form.work_phone = ''
        this.crud.form.mobile = ''
        this.crud.form.email = ''
        this.crud.form.status = ''
        this.crud.form.address_correspondence = ''    
        this.crud.form.registration_public_registry = null
        this.crud.form.year_end_date = null
        this.crud.form.registration_district = ''
        this.crud.form.registration_number = ''
        this.crud.form.registration_date = null
        this.crud.form.registration_book = ''
        this.crud.form.registration_volume = ''
        this.crud.form.registration_sheet = ''
        this.crud.form.observations = ''    
        this.crud.form.lost_motives_id = 0
        this.crud.form.categories_id = 0
        this.crud.form.sources_id = 0

        this.crud.form.staff_id = 0    
        this.crud.form.sellers_id = Helper.getSeller().id
        this.crud.form.customers_id = 0    
        
        this.crud.form.work_price = 0
        this.crud.form.work_budget = ''
        this.crud.form.work_currency_id = 1
        this.crud.form.work_recurrent = false

        this.modal.form.title = "Nuevo Potencial Cliente"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.iscompany = item.iscompany

        this.crud.form.id = item.id      
        this.crud.form.date = item.date
        this.crud.form.name = item.name
        this.crud.form.last_name = item.last_name
        this.crud.form.address = item.address
        this.crud.form.city = item.city
        this.crud.form.postal_code = item.postal_code
        this.crud.form.state = item.state
        this.crud.form.country = item.country
        
        if(item.marital_status) {
          this.crud.form.marital_status_id = { code: item.marital_status.id, label: item.marital_status.name }
        } else {
          this.crud.form.marital_status_id = 0
        }

        if(item.fiscal_condition) {
          this.crud.form.fiscal_conditions_id = { code: item.fiscal_condition.id, label: item.fiscal_condition.name }
        } else {
          this.crud.form.fiscal_conditions_id = 0  
        }
        
        if(item.document_type) {
          this.crud.form.documents_types_id = { code: item.document_type.id, label: item.document_type.name } 
        } else {
          this.crud.form.documents_types_id = 0
        }                

        if(item.document_fiscal_type) {
          this.crud.form.documents_fiscal_types_id = { code: item.document_fiscal_type.id, label: item.document_fiscal_type.name }
        } else {
          this.crud.form.documents_fiscal_types_id = 0
        }
                
        this.crud.form.documents_number = item.documents_number
        this.crud.form.documents_fiscal_number = item.documents_fiscal_number 
        this.crud.form.nationality = item.nationality
        this.crud.form.place_birth = item.place_birth
        this.crud.form.birthday = item.birthday
        this.crud.form.gender = this.getGenderSelect(item.gender)
        this.crud.form.main_activity = item.main_activity
        this.crud.form.phone = item.phone
        this.crud.form.work_phone = item.work_phone
        this.crud.form.mobile = item.mobile
        this.crud.form.email = item.email
        this.crud.form.status = this.getStatusSelect(item.status)
        this.crud.form.address_correspondence = item.address_correspondence    
        this.crud.form.registration_public_registry = item.registration_public_registry
        this.crud.form.year_end_date = item.year_end_date
        this.crud.form.registration_district = item.registration_district
        this.crud.form.registration_number = item.registration_number
        this.crud.form.registration_date = item.registration_date
        this.crud.form.registration_book = item.registration_book
        this.crud.form.registration_volume = item.registration_volume
        this.crud.form.registration_sheet = item.registration_sheet
        this.crud.form.observations = item.observations 

        if(item.lost_motive) {
          this.crud.form.lost_motives_id = { code: item.lost_motive.id, label: item.lost_motive.name }          
        } else {
          this.crud.form.lost_motives_id = 0
        }

        if(item.categories) {
          this.crud.form.categories_id = { code: item.categories.id, label: item.categories.name }           
        } else {
          this.crud.form.categories_id = 0
        }

        if(item.source) {
          this.crud.form.sources_id = { code: item.source.id, label: item.source.name }
        } else {
          this.crud.form.sources_id = 0
        }               
        
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id    
        this.crud.form.customers_id = item.customers_id

        this.crud.form.work_price = item.work_price
        this.crud.form.work_budget = item.work_budget
        this.crud.form.work_currency_id = item.work_currency_id
        this.crud.form.work_recurrent = item.work_recurrent

        this.modal.form.title = "Editar Potencial Cliente"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - ('+ this.crud.form.name + ')?', {
          title: 'Borrar Potencial Cliente',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarLeads(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterQuery()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la operación?', {
          title: 'Guardar Potencial Cliente',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'Aceptar',
          cancelVariant: 'outline-dark',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {      
            let loader = this.$loading.show();                      
            
            var customFiscalConditions = this.crud.form.fiscal_conditions_id
            var customDocumentsTypes = this.crud.form.documents_types_id
            var customDocumentsFiscalTypes = this.crud.form.documents_fiscal_types_id
            var customMaritalStatus = this.crud.form.marital_status_id
            var customLostMotives = this.crud.form.lost_motives_id
            var customCategories = this.crud.form.categories_id
            var customSources = this.crud.form.sources_id            
            var customGender = this.crud.form.gender
            var customStatus = this.crud.form.status
                        
            this.crud.form.fiscal_conditions_id = this.crud.form.fiscal_conditions_id.code
            this.crud.form.documents_types_id = this.crud.form.documents_types_id.code
            this.crud.form.documents_fiscal_types_id = this.crud.form.documents_fiscal_types_id.code          
            this.crud.form.marital_status_id = this.crud.form.marital_status_id.code
            this.crud.form.lost_motives_id = this.crud.form.lost_motives_id.code
            this.crud.form.categories_id = this.crud.form.categories_id.code            
            this.crud.form.sources_id = this.crud.form.sources_id.code                     
            
            if(this.crud.form.gender) {
              this.crud.form.gender = this.crud.form.gender.code
            } else {
              this.crud.form.gender = null
            }
            
            if(this.crud.form.status) {
              this.crud.form.status = this.crud.form.status.code
            } else {
              this.crud.form.status = null
            }

            if (this.crud.form.id) {
              var result = serviceAPI.editarLeads(this.crud.form);
            } else {
              var result = serviceAPI.agregarLeads(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterQuery()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {              
              this.crud.form.fiscal_conditions_id = customFiscalConditions
              this.crud.form.documents_types_id = customDocumentsTypes
              this.crud.form.documents_fiscal_types_id = customDocumentsFiscalTypes
              this.crud.form.marital_status_id = customMaritalStatus
              this.crud.form.lost_motives_id = customLostMotives
              this.crud.form.categories_id = customCategories
              this.crud.form.sources_id = customSources
              this.crud.form.gender = customGender
              this.crud.form.status = customStatus
              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })               
      },   

      // FILTER
      filterLoad() {                
        this.filterLoadStaff()                     
        this.filterLoadType()
                
        this.restoreFilterStorage() 
      },
      filterLoadStaff() {
        var result = serviceAPI.obtenerStaff()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.staff = []
          data.forEach(element => {                            
            if(!element.seller) {             
              this.arr.select.staff.push({ code: element.id, label: element.name })    
            }                    
          });          
        })   
      }, 
      filterLoadType() {
        this.arr.select.type = []        
        this.arr.select.type.push({ code: 'person', label: 'Persona' })                        
        this.arr.select.type.push({ code: 'company', label: 'Empresa' })                                
      },
      filterQuery(){
        let loader = this.$loading.show()   
   
        this.filter.sellers = []
        this.filter.sellers.push({
          code: Helper.getSeller().id,
          label: Helper.getSeller().name,
        })   

        var result = serviceAPI.filtrarLeads(this.filter)        
        result.then((response) => {
          loader.hide()

          var data = response.data
          this.table.items = data
          this.arr.leads = data
          this.prepareExport(data)

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true 
            
            if(this.table.items[this.table.rowSelected].attachments) {
              this.tableSub.items = this.table.items[this.table.rowSelected].attachments
            }                              
          } 
          
          this.saveFilterStorage()
          this.loadSalesFlowAnalysis()
        })
        .catch(error => {
          loader.hide()          
          //this.$awn.alert(Error.showError(error))
        });          
      }, 

      // EXPORT
      prepareExport(data) {
        let loader = this.$loading.show(); 
        this.arr.export = []                     
        data.forEach(element => {          
          var staffID = 0            
          var staffName = ''
          var sellersID = 0
          var sellersName = ''
          if(element.staff_id) {
            staffID = element.staff.id
            staffName = element.staff.name
          } else {
            if(element.seller_id) {
              sellersID = element.seller.id
              sellersName = element.seller.name
            } else {
              staffID = 0
              staffName = ''
              sellersID = 0
              sellersName = ''
            }
          }

          var categoriesID = 0
          var categoriesName = ''
          if(element.categories_id) {
            categoriesID = element.categories.id
            categoriesName = element.categories.name
          }

          var sourceID = 0
          var sourceName = ''
          if(element.sources_id) {
            sourceID = element.source.id
            sourceName = element.source.name
          }

          var fiscalConditionsID = 0
          var fiscalConditionsName = ''
          if(element.fiscal_conditions_id) {
            fiscalConditionsID = element.fiscal_condition.id
            fiscalConditionsName = element.fiscal_condition.name
          }

          var documentTypeID = 0
          var documentTypeName = ''
          if(element.documents_types_id) {
            documentTypeID = element.document_type.id
            documentTypeName = element.document_type.name
          }

          var documentFiscalTypeID = 0
          var documentFiscalTypeName = ''
          if(element.documents_fiscal_types_id) {
            documentFiscalTypeID = element.document_fiscal_type.id
            documentFiscalTypeName = element.document_fiscal_type.name
          }

          var maritalStatusID = 0
          var maritalStatusName = ''
          if(element.marital_status_id) {
            maritalStatusID = element.marital_status.id
            maritalStatusName = element.marital_status.name
          }

          var lostMotiveID = 0
          var lostMotiveName = ''
          if(element.lost_motives_id) {
            lostMotiveID = element.lost_motive.id
            lostMotiveName = element.lost_motive.name
          }

          var workCurrencyID = 0
          var workCurrencyName = ''
          if(element.work_currency_id) {
            workCurrencyID = element.currency.id
            workCurrencyName = element.currency.name
          }          

          this.arr.export.push({
            id: element.id,
            date: element.date,      
            name: element.name,
            last_name: element.last_name,
            address: element.address,
            city: element.city,
            postal_code: element.postal_code,
            state: element.state,
            country: element.country,          
            fiscalConditionsID: fiscalConditionsID,
            fiscalConditionsName: fiscalConditionsName,
            documentTypeID: documentTypeID,
            documentTypeName: documentTypeName,
            documents_number: element.documents_number,            
            documentFiscalTypeID: documentFiscalTypeID,
            documentFiscalTypeName: documentFiscalTypeName,                      
            documents_fiscal_number: element.documents_fiscal_number,
            maritalStatusID: maritalStatusID,
            maritalStatusName: maritalStatusName,            
            nationality: element.nationality,
            place_birth: element.place_birth,
            birthday: element.birthday,
            gender: element.gender,
            main_activity: element.main_activity,
            phone: element.phone,
            work_phone: element.work_phone,
            mobile: element.mobile,
            email: element.email,
            status: element.status,
            address_correspondence: element.address_correspondence,
            registration_public_registry: element.registration_public_registry,
            year_end_date: element.year_end_date,
            registration_district: element.registration_district,
            registration_number: element.registration_number,
            registration_date: element.registration_date,
            registration_book: element.registration_book,
            registration_volume: element.registration_volume,
            registration_sheet: element.registration_sheet,
            observations: element.observation,
            lostMotiveID: lostMotiveID,
            lostMotiveName: lostMotiveName,
            categoriesID: categoriesID,
            categoriesName: categoriesName,     
            sourceID: sourceID,
            sourceName: sourceName,     
            sellersID: sellersID,
            sellersName: sellersName,
            staffID: staffID,
            staffName: staffName,  
            workPrice: element.work_price,
            workBudget: element.work_budget,          
            workCurrencyID: workCurrencyID, 
            workCurrencyName: workCurrencyName, 
          })          
        });
        loader.hide()
      },

      // SELECT CONTROLS
      selectLoad(){
        this.selectGender()     
        this.selectStatus() 
        this.selectLostMotives()
        this.selectCategories()
        this.selectSources()
        this.selectFiscalConditions()
        this.selectTypesDocuments()
        this.selectTypesDocumentsFiscal()
        this.selectMaritalStatus()
        this.selectContracts()
      },
      selectGender(){
        this.arr.select.gender = []        
        this.arr.select.gender.push({ code: 'male', label: 'Masculino' })                        
        this.arr.select.gender.push({ code: 'female', label: 'Femenino' })                        
        this.arr.select.gender.push({ code: 'others', label: 'Otros' })      
      },
      selectStatus(){        
        this.arr.select.status = []        
        this.arr.select.status.push({ code: 'interested', label: 'Interesados' })                        
        this.arr.select.status.push({ code: 'in process', label: 'En Proceso' })                        
        this.arr.select.status.push({ code: 'approval', label: 'Aprobación' })                        
        this.arr.select.status.push({ code: 'won', label: 'Ganado' })                        
        this.arr.select.status.push({ code: 'lost', label: 'Perdido' })                        
      },
      selectLostMotives(){
        var result = serviceAPI.obtenerLeadsLostMotive()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.lostMotives = []
          data.forEach(element => {                                                  
            this.arr.select.lostMotives.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectCategories(){
        var result = serviceAPI.obtenerLeadsCategories()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.categories = []
          data.forEach(element => {                                                  
            this.arr.select.categories.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectSources(){
        var result = serviceAPI.obtenerLeadsSources()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.sources = []
          data.forEach(element => {                                                  
            this.arr.select.sources.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectFiscalConditions(){
        var result = serviceAPI.obtenerLeadsFiscalConditions()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.fiscalConditions = []
          data.forEach(element => {                                                  
            this.arr.select.fiscalConditions.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectTypesDocuments(){
        var result = serviceAPI.obtenerLeadsTypesDocuments()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.typesDocuments = []
          data.forEach(element => {                                                  
            this.arr.select.typesDocuments.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectTypesDocumentsFiscal(){
        var result = serviceAPI.obtenerLeadsTypesDocumentsFiscal()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.typesDocumentsFiscal = []
          data.forEach(element => {                                                  
            this.arr.select.typesDocumentsFiscal.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectMaritalStatus(){
        var result = serviceAPI.obtenerLeadsMaritalStatus()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.maritalStatus = []
          data.forEach(element => {                                                  
            this.arr.select.maritalStatus.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectContracts() {
        var result = serviceAPI.obtenerContrato()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.contracts = []
          data.forEach(element => {                                                  
            this.arr.select.contracts.push({ code: element.id, label: element.name })                
            this.arr.contracts.push(element)                
          });          
        })  
      },

      // LOAD CONTROL 
      loadCurrency (object) {
        if(object){
          this.crud.form.work_currency = object
          this.crud.form.work_currency_id = object.id
        } else {
          this.crud.form.work_currency = null
          this.crud.form.work_currency_id = 0
        }
      },                   

      // GETTERS
      getGenderSelect(value) {
        var result = null
        this.arr.select.gender.forEach(element=>{
          if(element.code==value) {
            result = element
          }
        })
        return result
      },
      getStatusSelect(value) {
        var result = null
        this.arr.select.status.forEach(element=>{
          if(element.code==value) {
            result = element
          }
        })
        return result
      },  
      getStatusName(value) {
        var name = ''
        this.arr.select.status.forEach(element=>{
          if(element.code==value) {
            name = element.label
          }
        })
        return name
      },
      getTotalByStatus(value) {
        var sum = []
        this.arr.leads.forEach(element=>{
          if(value == element.status) {
            if(!sum.includes(element.currency.code)) {
              if(element.work_recurrent) {
                sum['R-' + element.currency.code] = 0
              } else {
                sum[element.currency.code] = 0
              }
              
            } 
          }         
        })

        this.arr.leads.forEach(element=>{
          if(value == element.status) {
            var code = element.currency.code       

            if(element.work_recurrent) {              
              sum['R-' + code] = sum['R-' + code] + parseFloat(element.work_price)
            } else {
              sum[code] = sum[code] + parseFloat(element.work_price)
            }
          }
        })

        //armo html para mostrar total                
        var htmlResult = ''
        if(sum) {          
          for(let prop of Object.keys(sum)) {
            if(prop.includes('R-')) {
              let code = prop.substring(2)
              htmlResult = htmlResult + '<b>' + Intl.NumberFormat('es-AR',{style:'currency',currency:code}).format(sum[prop]) + '</b> (R)<br>'
            } else {
              let code = prop
              htmlResult = htmlResult + '<b>' + Intl.NumberFormat('es-AR',{style:'currency',currency:code}).format(sum[code]) + '</b><br>'
            }
            
          }          
        }
        return htmlResult        
      },
      getStatusTable(value) {
        var result = null
        this.arr.select.status.forEach(element=>{
          if(element.code==value) {
            var color = ''
            if(element.code == 'interested') {
              color = 'darkblue'
            }
            if(element.code == 'in process') {
              color = 'darkorange'
            }
            if(element.code == 'approval') {
              color = 'darkmagenta'
            }
            if(element.code == 'won') {
              color = 'darkgreen'
            }
            if(element.code == 'lost') {
              color = 'darkred'
            }

            result = {
              code: element.code,
              label: element.label,
              color: color,
            }
          }
        })
        return result
      }, 

      // FUNCTIONS --> EXTERNAL APPROVAL
      irDetail(item){        
        this.$router.push({ name: 'SalesFlowStaffDetailLeads', params: {leadsID: item.id} })
      },
      externalApproval(item){ 
        let loader = this.$loading.show();
        var result = serviceAPI.enviarNotificationExternalApproval({
          id: item.id
        });

        result.then((response) => {              
          loader.hide()          
          this.$awn.success("Notificación enviada por email");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
        //this.$router.push({ name: 'SalesFlowPublicApproval', params: {leadsID: item.id} })
      },

      // FUNCTIONS --> CONTRACT
      openContract(item) {
        this.modal.contract.content = ''
        this.modal.contract.urlPDF = ''
        this.modal.contract.contractObject = null
        this.modal.contract.leadID = item.id      
        this.modal.contract.leadObject = item

        this.modal.contract.title = "Contrato"
        this.modal.contract.active = true        
      },
      showContract(item) {
        this.modal.contract.urlPDF = ''        
        this.modal.contract.content = ''        
        if(item) {                  
          this.arr.contracts.forEach(element => {            
            if(element.id == item.code) {
              this.modal.contract.content = element.content              
            }
          });
        }      
      },
      printContract() {
        if(!this.modal.contract.content) {
          this.$awn.alert("Seleccione un tipo de contrato")
          return false
        }

        let loader = this.$loading.show()
        this.modal.contract.urlPDF = ''

        var result = serviceAPI.crearContrato({
          contractID: this.modal.contract.contractObject.code,
          leadID: this.modal.contract.leadID,
        })        
        result.then((response) => {        
          var data = response.data              
          this.modal.contract.urlPDF = data
          loader.hide()          
        })     
        .catch(error => {
          loader.hide()                    
          this.$awn.alert(Error.showError(error))
        });                                
      },

      // FUNCTIONS --> FIRM
      openChangeFirm(item) {
        this.modal.firm.leadID = item.id
        if(item.firm.length>0) {
          this.modal.firm.imagen = [{ path: item.firm }]
        } else {
          this.modal.firm.imagen = []
        }

        this.modal.firm.title = "Firma"
        this.modal.firm.active = true       
      },
      changeFirm(){
        let loader = this.$loading.show();

        var image = new FormData();
        this.modal.firm.imagen.forEach((value, key) => {
          image.append(key, value.path);
        })
                
        var result = serviceAPI.changeFirmLeads(JSON.stringify({
          leadID: this.modal.firm.leadID,
          image: 0
        }), image);        

        result.then((response) => {          
          this.modal.firm.active = false
          loader.hide()
          this.filterQuery()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      
      // STATUS
      nextStage(item){        
        if(item.status == 'in process') {
          if(item.attachments.length==0) {
            this.$awn.alert('Debe cargar mínimo un archivo adjunto para pasar a la siguiente etapa');
            this.kanban.forceUpdate = this.kanban.forceUpdate + 1
            this.loadSalesFlowKanban()                   
            return false
          }
        }

        if(item.status == 'approval') {
          this.crud.form.lost_motives_id = 0
          this.modal.stage.value = ''
          this.modal.stage.id = item.id

          this.modal.stage.title = "Etapa Final"
          this.modal.stage.active = true
        } else {
          let loader = this.$loading.show();     

          var data = {
            id: item.id,   
            status: this.setNextStage(item.status)
          }                         
          var result = serviceAPI.setStatusLeads(data);        

          result.then((response) => {          
            loader.hide()
            this.filterQuery()
            this.$awn.success("Datos guardados con éxito");
          })
          .catch(error => {                        
            loader.hide()
            this.$awn.alert(Error.showError(error));
          })
        }
      },
      showNextStage(item){      
        if(item.status == 'won' || item.status == 'lost') {
          return false
        } else {
          return true
        }
      },
      previousStage(item){
        let loader = this.$loading.show();     
        var data = {
          id: item.id,    
          status: this.setPreviousStage(item.status)      
        }                         
        var result = serviceAPI.setStatusLeads(data);        

        result.then((response) => {          
          loader.hide()
          this.filterQuery()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {                        
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },  
      showPreviousStage(item){
        if(item.status == 'interested') {
          return false
        } else {
          return true
        }
      },
      setNextStage(value) {
        var nextStage = ''
        if(value == 'interested') {
          nextStage = 'in process'
        }
        
        if(value == 'in process') {
          nextStage = 'approval'
        }
        return nextStage
      },
      setPreviousStage(value) {
        var nextStage = ''
        if(value == 'won' || value == 'lost') {
          nextStage = 'approval'
        }
        
        if(value == 'approval') {
          nextStage = 'in process'
        }

        if(value == 'in process') {
          nextStage = 'interested'
        }
        return nextStage
      },
      saveStageFinish() {
        let loader = this.$loading.show();     

        var data = {
          id: this.modal.stage.id,   
          status: this.modal.stage.value,
          lostMotive: this.crud.form.lost_motives_id.code 
        }                         
        var result = serviceAPI.setStatusLeads(data);        

        result.then((response) => {  
          this.modal.stage.active = false        
          loader.hide()
          this.filterQuery()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {                        
          this.modal.stage.active = false
          loader.hide()
          this.$awn.alert(Error.showError(error));

          this.kanban.forceUpdate = this.kanban.forceUpdate + 1
          this.loadSalesFlowKanban()   
        })
      },
      closeStageFinish() {
        this.modal.stage.active=false

        this.kanban.forceUpdate = this.kanban.forceUpdate + 1
        this.loadSalesFlowKanban()       
      },
      validStatusAction(item, newStatus) {
        var action = ''
        if(item.status == 'interested') {
          if(newStatus == 'in process') {
            action = 'next'
          }
        } else if(item.status == 'in process') {
          if(newStatus == 'approval') {
            action = 'next'
          } else if (newStatus == 'interested') {
            action = 'prev'
          }
        } else if(item.status == 'approval') {
          if(newStatus == 'won' || newStatus == 'lost') {
            action = 'next'
          } else if (newStatus == 'in process') {
            action = 'prev'
          }
        } else if(item.status == 'won') {
          if(newStatus == 'approval') {
            action = 'prev'
          }
        } else if(item.status == 'lost') {
          if(newStatus == 'approval') {
            action = 'prev'
          }
        }         
        return action
      },

      // FORM SECONDS
      addSub(item) {        
        this.crud.formSub.id = 0
        this.crud.formSub.leads_id = item.id
        this.crud.formSub.name = '' 
        this.crud.formSub.file = null
        this.crud.formSub.fileLink = ''

        this.modal.formSub.title = "Nuevo Adjunto"
        this.modal.formSub.active = true
      },
      editSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.leads_id = item.leads_id
        this.crud.formSub.name = item.name      
        this.crud.formSub.file = null
        this.crud.formSub.fileLink = item.file    

        this.modal.formSub.title = "Editar Adjunto"
        this.modal.formSub.active = true
      },
      removeSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.formSub.id + ') - '+ this.crud.formSub.name + '?', {
          title: 'Borrar Adjunto',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {          
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarLeadsAttachments(this.crud.formSub.id);

            result.then((response) => {      
              this.modal.formSub.active = false        
              loader.hide()
              this.filterQuery()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }          
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveSub() {   
        let loader = this.$loading.show();

        var file = new FormData();
        if(this.crud.formSub.fileLink) {
          file.append("file", this.crud.formSub.fileLink);
        } else {        
          if(this.crud.formSub.file) {          
            file.append("file", this.crud.formSub.file);
          }
        }

        if (this.crud.formSub.id) {
          var result = serviceAPI.editarLeadsAttachments(JSON.stringify(this.crud.formSub), file);          
        } else {
          var result = serviceAPI.agregarLeadsAttachments(JSON.stringify(this.crud.formSub), file);          
        }

        result.then((response) => {
          this.modal.formSub.active = false
          loader.hide()
          this.filterQuery()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_sales_flow_leads', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_sales_flow_leads')) {
          this.filter = JSON.parse(Storage.getValue('filter_sales_flow_leads'))
        }         
      },  
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_sales_flow', JSON.stringify(this.tabs))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_sales_flow')) {
          this.tabs = JSON.parse(Storage.getValue('tabs_view_sales_flow'))
        }         
      },        
      
      // LOAD TABS
      disableAllTabs() {
        this.tabs.list = false
        this.tabs.kanban = false
        this.tabs.analysis = false
      },      
      loadSalesFlowList() {
        this.disableAllTabs()
        this.tabs.list = true
        this.saveStatusTabsStorage()
      },
      loadSalesFlowKanban(forceSaveTabs=false) {
        if(forceSaveTabs) {
          this.disableAllTabs()
          this.tabs.kanban = true
          this.saveStatusTabsStorage()
        }

        this.kanban.stages = []
        if(this.arr.select.status) {
          this.arr.select.status.forEach(element => {
            this.kanban.stages.push(element.code)
          });                   
        }
        if(this.arr.leads) {
          this.kanban.blocks = []
          this.arr.leads.forEach(element => {
            this.kanban.blocks.push({
              id: element.id,
              status: element.status,
              title: element.name,
              data: element,
            })
          });          
        }
      },
      loadSalesFlowAnalysis(forceSaveTabs=false) {     
        if(forceSaveTabs) {   
          this.disableAllTabs()
          this.tabs.analysis = true
          this.saveStatusTabsStorage()
        }

        this.pivot.isDataLoading = true
        this.pivot.data = this.arr.leads         
        this.pivot.isDataLoading = false      
      },       

      // KANABN
      updateBlock(id, status) {    
        var obj = null
        this.arr.leads.forEach(element => {
          if(element.id == id) {
            obj = element
          }
        });    

        if(this.validStatusAction(obj, status) == 'next'){
          this.nextStage(obj)               
          return true
        } 
        
        if(this.validStatusAction(obj, status) == 'prev'){
          this.previousStage(obj)               
          return true          
        }         

        this.$awn.alert("No puede realizar esta operación");
        this.kanban.forceUpdate = this.kanban.forceUpdate + 1
        this.loadSalesFlowKanban()        
      },         
    }    
  }
</script>

<style>
  .crud-leads-select {
    background: #fff;
  }
  .crud-leads-bg-form {
    background: #e4e5e64d;
  }
  .crud-leads-table-title-person {
    color: darkolivegreen;
    text-decoration: underline;
    font-weight: 800;
    font-size: 15px;
  }
  .crud-leads-table-title-company {
    color: darkslategray;
    text-decoration: underline;
    font-weight: 800;
    font-size: 15px;
  }  
  .crud-leads-table-title {
    color: black;
    text-decoration: underline;
    font-weight: 500;    
  }    
  .crud-leads-table-title-name {
    color: black;    
    font-weight: 700;    
  }   
  .crud-leads-table-title-status {        
    font-weight: 700;    
  }
  .crud-leads-modal-customer-won {
    color: darkgreen;
    font-size: 18px;
  }       
  .crud-leads-modal-customer-lost {
    color: darkred;
    font-size: 18px;
  }
  .crud-leads-title-attachment {
    font-size: 17px;
  }
  .crud-lead-print-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }  
  .crud-leads-preview-contract {
    background: #e5e5e5;    
    padding: 15px;
    height: 450px;        
  }

  .crud-leads-preview-contract .crud-leads-preview-contract-alert {
    background: #fff;        
  }
  .crud-leads-preview-contract .crud-leads-preview-contract-html {
    background: #fff;
    height: 450px;     
    padding: 15px;   
    overflow: overlay;
  }  
  .crud-leads-preview-open-pdf {
    margin-top: 10px;
  }
  .sales-flow-leads-image .image-container{
    width: 100%
  }
</style>

<style>
  .sales-flow-pivot table thead tr th:empty {
    background: #fff;
    color: #fff;
    text-align: center;
    border: none;
  }  
  .sales-flow-pivot table thead tr {
    background: #2f353a;
    color: #fff;
    text-align: center;
  }  
  .sales-flow-pivot .table th, .table td {
      padding: 1px;
  }
  .sales-flow-pivot table tbody tr th {
    background: #2f353a;
    color: #fff;
    text-align: right;    
    vertical-align: middle !important;    
    padding-right: 5px !important;
  }    
  .sales-flow-pivot table tbody tr td {    
    color: #2f353a;
    background: #e2e2e2;
    text-align: right;    
    font-weight: 600 !important;
    vertical-align: middle !important;    
    padding-right: 5px !important;
  }      
</style>

<style>
  .drag-column-interested {
    background: darkblue;
    color: white;
  }
  .drag-column-in {
    background: darkorange;
    color: white;
  }
  .drag-column-approval {
    background: darkmagenta;
    color: white;
  }    
  .drag-column-won {
    background: darkgreen;
    color: white;
  }    
  .drag-column-lost {
    background: darkred;
    color: white;
  }        
  .drag-item{  
    background: rgba(255, 255, 255, 0.4);
  }
  .sales-flow-kanban-date {
    position: absolute;
    right: 20px;
  }
  .sales-flow-kanban-hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid rgb(255 255 255);
  }
</style>